@use '../config/' as *;
@forward '../main-scss/';
@forward './commons';
@forward './stats-nav';
@forward './table';
.waf-statslisting {
    --card-height: 26rem;
    padding: 0 var(--space-1);
    // @extend %pt-5;
    @extend %mb-3;
    .waf-head {
        padding-right: 12rem;
        @extend %flex-sb-n;
        .title {
            @extend %mb-2;
        }
    }
    .waf-body {
        padding-top: 3rem;
        @extend %relative;
        @extend %mx-3;
    }
    .player-card ~ .stats-table {
        .table-head {
            margin-bottom: var(--card-height);
        }
    }
    .table {
        @extend %mt-6;
        &-more-data {
            @extend %my-3;
            p {
                opacity: 0.5;
            }
        }
        .table-data {
            min-width: 6rem;
            a:hover {
                text-decoration: underline;
            }
        }
        .table-data-club {
            width: 11rem;
        }
    }
    .player-card {
        position: absolute;
        left: 0;
        height: var(--card-height);
        top: 11rem;
        background: linear-gradient(356.76deg, var(--club-primary) -80.63%, var(--club-secondary) 48.47%);
        @extend %w-100;
        @extend %flex-sb-c;
        @extend %relative;
        @extend %white;
        .position {
            position: absolute;
            top: 1rem;
            left: 2rem;
            .text {
                -webkit-text-stroke: .1rem hsl(var(--hsl-white)/.5);
                color: transparent;
                @extend %font-20-pb;
            }
        }
        .club-name {
            @extend %white;
            &:hover {
                text-decoration: underline;
            }
            .full-name {
                text-transform: none;
            }
        }
        .player,
        .player-name {
            line-height: 1.2;
            @extend %d-block;
            @extend %mb-2;
            &:hover {
                text-decoration: underline;
                @extend %white;
            }
            .name {
                @extend %d-block;
                @extend %font-16-pr;
                @extend %uppercase;
                @extend %white;
                &:last-child {
                    font-weight: 700;
                }
            }
            &-role {
                @extend %pb-2;
                @extend %mb-2;
                @include border(1, white, 3, bottom);
                .role {
                    width: max-content;
                    line-height: 1.8;
                    @extend %uppercase;
                    @extend %px-1;
                    @extend %font-8-pr;
                    @extend %white-bg-1;
                }
            }
            &-image {
                width: 45%;
                max-height: 21rem;
                align-self: flex-end;
                @extend %flex-c-fe;
                @extend %h-100;
                .image {
                    @extend %w-100;
                    @extend %h-100;
                }
            }
            &-info {
                flex: 1;
                width: 55%;
                @extend %px-1;
            }
            &-meta {
                @extend %pr-4;
                @extend %mt-2;
                @include grid(2)
            }
            &-data {
                @extend %white-bg-1;
                @extend %p-1;
                @extend %text-center;
                &-highlight .text.value {
                    color: var(--accent);
                }
                .text {
                    // height: 2.6rem;
                    @extend %font-8-pr;
                    @extend %white-7;
                    @extend %flex-c-c;
                }
                .value {
                    line-height: 1;
                    @extend %mt-1;
                    @extend %font-22-pb;
                    @extend %white;
                }
            }
        }
    }
    .club {
        &-img {
            width: 4rem;
            height: 4rem;
            flex-shrink: 0;
            @extend %white-bg;
            @extend %circle-radius;
        }
        &-wrap {
            @extend %flex-n-c;
            @extend %gap-1;
        }
        &-name {
            @extend %font-8-pr;
            .short-name {
                @extend %d-none;
            }
        }
    }
    .waf-footer {
        @extend %pt-4;
        @extend %px-3;
        .accordion {
            &-btn {
                @extend %flex-sb-c;
                @extend %p-2;
                @extend %w-100;
                @extend %white-bg;
                &[aria-expanded=true] {
                    background: linear-gradient(108.9deg, clr(secondary) 12.75%, clr(primary) 162.72%);
                    @extend %white;
                    .btn-icon {
                        rotate: 180deg
                    }
                }
                .btn-text {
                    @extend %font-14-pb;
                    @extend %text-left;
                }
                .btn-icon {
                    width: 3rem;
                    flex-shrink: 0;
                    transition: 300ms;
                    &::before {
                        @include icon("chevron-down");
                    }
                }
            }
            &-item {
                &:not(:first-child) {
                    position: relative;
                    &::after {
                        content: "";
                        width: calc(100% - 2rem);
                        height: 0.1rem;
                        position: absolute;
                        top: 0;
                        inset-inline: 0;
                        margin: auto;
                        @extend %secondary-bg-5;
                    }
                }
            }
        }
        .award {
            &-head {
                border-bottom: .1rem solid clr(black, 2);
                @extend %flex-n-c;
                @extend %gap-4;
                @extend %pb-3;
                @extend %mb-3;
                .icon {
                    width: 4.5rem;
                    height: 4.5rem;
                    @include background("svg/trophy-hand.svg", no-repeat center / contain);
                }
            }
            &-title {
                @extend %font-16-pb;
                @extend %uppercase;
            }
            &-name {
                @extend %uppercase;
                .name {
                    line-height: 1.3;
                    @extend %font-14-pb;
                }
                .first-name {
                    font-weight: 400;
                }
            }
            &-item {
                @extend %flex-n-c;
                @extend %gap-2;
                &:not(:first-child) {
                    border-top: .1rem solid clr(black, 2);
                }
            }
            &-img {
                width: 6rem;
                height: 6rem;
                flex-shrink: 0;
                @extend %circle-radius;
                @extend %hidden;
            }
            &-section {
                @extend %p-4;
                &:not(:first-child) {
                    border-top: .1rem solid clr(secondary);
                }
                &-player {
                    .award-img {
                        box-shadow: 0px 0px 2px 2px clr(black, 2) inset;
                        @extend %white-bg;
                    }
                }
                &-club {
                    @extend %secondary-bg-1;
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-statslisting {
        --card-height: 21rem;
        .waf-body {
            padding-top: 0;
            padding-inline: 0;
            margin-inline: 0;
        }
        .table-head {
            .table-data-player {
                padding-left: 2.5rem;
            }
        }
        .table-data-club {
            flex-basis: 42%
        }
        .table-left {
            width: 30%;
        }
        .table-right {
            width: 70%;
        }
        .club-name {
            display: block;
        }
        .player-card {
            top: 5rem;
            .position {
                align-self: center;
                position: unset;
                margin-left: 5.5rem;
                .text {
                    font-size: 6rem;
                }
            }
            .player,
            .player-name {
                &-image {
                    width: 20rem;
                }
                &-info {
                    display: grid;
                    grid-template-columns: 43% 1fr;
                    margin-left: 5%;
                }
                .name {
                    display: inline;
                    font-size: 2.6rem;
                }
                &-role {
                    max-width: 30rem;
                    .role {
                        font-size: 1rem;
                        padding-inline: var(--space-2);
                    }
                }
                &-meta {
                    grid-template-columns: repeat(4, 1fr);
                    grid-row: 1 / span 4;
                    grid-column-start: 2;
                }
                &-data {
                    background: transparent;
                    .text {
                        display: none;
                        &.value {
                            display: block;
                            font-size: 3rem;
                        }
                    }
                    &.highlight {}
                }
            }
            .club {
                &-img {
                    width: 6rem;
                    height: 6rem;
                    padding: 0.5rem;
                }
                &-name {
                    display: block;
                    font-size: 1.4rem;
                }
            }
        }
        .waf-footer {
            .accordion {
                &-btn {
                    padding: var(--space-4);
                    .btn-text {
                        font-size: 1.6rem;
                    }
                }
            }
            .award {
                &-section {
                    display: flex;
                    &:last-child {
                        flex: 1;
                    }
                }
                &-head {
                    width: 22rem;
                    margin: 0;
                    padding: 0;
                    border: 0;
                }
            }
        }
    }
    .stats-navigation {
        top: 25.3rem;
    }
}
@include mq(col-lg) {
    .waf-statslisting {
        .waf-footer {
            padding: 0;
            .accordion-body-wrap {
                display: flex;
            }
            .award-section:not(:first-child) {
                border-top: 0;
                border-left: .1rem solid clr(secondary);
            }
            .award-list {
                display: flex;
            }
            .award-item {
                border-left: .1rem solid clr(secondary, 3);
                padding-left: 1rem;
                &:not(:first-child) {
                    border: 0;
                    border-left: .1rem solid clr(secondary, 3);
                }
            }
        }
    }
}
@include mq(col-xxl) {
    .waf-statslisting {
        .player-card {
            .player-info {
                margin-left: 10.5%;
                grid-template-columns: 43% 1fr;
            }
        }
    }
}