@use '../config/' as *;
.info-section {
    position: absolute;
    top: 14rem;
    right: 2rem;
    z-index: var(--z-info-section);
    @extend %flex-c-c;
    .btn-info {
        font-size: 0;
        &::before {
            @include icon(info, 20);
        }
        &[aria-expanded=true] ~ .info-list {
            opacity: 1;
            pointer-events: initial;
        }
    }
    .info {
        &-list {
            width: max-content;
            position: absolute;
            top: 50%;
            right: 3rem;
            transform: translateY(-50%);
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;
            @extend %secondary-light-bg;
            @extend %white;
            @extend %p-3;
            &::after {
                content: "";
                width: 1rem;
                height: 1rem;
                transform: rotate(45deg);
                right: -0.5rem;
                @extend %secondary-light-bg;
                @extend %position-y-center;
            }
        }
    }
}
.stats-table {
    @extend %flex;
    @extend %quarter-radius;
    @extend %hidden;
    @extend %white-bg;
    .table {
        @extend %p-2;
        &-right {
            @extend %x-auto;
        }
        &-left {
            width: 45%;
        }
        &-right {
            width: 55%;
        }
        &-data {
            justify-content: center;
            @extend %p-2-1;
            &-club,
            &-name,
            &-player {
                justify-content: flex-start
            }
        }
        &-body {
            .text {
                @extend %secondary-light;
            }
            .table-data-goals,
            .table-data-highlight {
                .text {
                    @extend %primary;
                }
            }
            .table-data-games-played {
                .text {
                    font-weight: 400;
                }
            }
        }
        &-row {
            justify-content: flex-start;
            &:not(:last-child) {
                @include border(1, black, 1, bottom);
            }
        }
    }
    .table-data-position {
        width: 5rem;
        justify-content: center;
        flex-shrink: 0;
    }
    .full-name {
        @extend %d-none;
    }
    .text {
        @extend %capitalize;
        @extend %font-14-pb;
    }
    .player-name {
        font-size: 1.4rem;
        line-height: 1.4rem;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 10rem;
        @extend %text-left;
        @extend %mb-0;
    }
    .table-data-club {
        width: 8rem;
        flex-shrink: 0;
        .short-name {
            display: block;
        }
    }
    .name {
        text-transform: unset;
        @extend %font-14-pb;
    }
}
@include mq(col-md) {
    .info-section {
        display: none;
    }
    .stats-table {
        .table {
            &-left {
                width: 35%;
            }
            &-right {
                width: 65%;
            }
            &-data {
                width: 20rem;
                justify-content: center;
                &-name,
                &-player,
                &-club {
                    justify-content: flex-start;
                }
                &-club {
                    .full-name {
                        text-transform: none;
                    }
                }
            }
        }
        .table-data-position {
            flex: 0 0 6rem;
            flex-basis: 14rem;
            flex-shrink: 0;
        }
        .name {
            display: inline;
        }
        .table-data-club {
            flex: 0 0 60%;
            &-name {
                display: block;
                text-align: left;
            }
            .short-name {
                display: none;
            }
        }
        .player-name {
            width: unset;
        }
    }
}